<template>
  <div>
    <sb-render v-for="child in item.body" :item="child" :key="child.uuid" />
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import MetaInfo from 'theme/mixins/pages/MetaInfo'

export default {
  extends: Blok,
  name: 'PageBlok',
  mixins: [MetaInfo]
}
</script>
